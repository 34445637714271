import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import * as styles from './Template.module.css'

const Template = ({ template }) => {
  const { templateTitle, description, googleDocsLink, blogLink } = template

  return (
    <div>
      <div className={styles.templateContainer}>
        <h2 className={styles.title}>{templateTitle}</h2>
        <p className={styles.description}>{description}</p>
        <div>
          {blogLink && (
            <Link to={blogLink} className={styles.link}>
              Blog
            </Link>
          )}
          {googleDocsLink && (
            <a
              className={styles.link}
              href={googleDocsLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Google Docs
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Template

Template.propTypes = {
  template: PropTypes.shape({
    templateTitle: PropTypes.string,
    description: PropTypes.string,
    googleDocsLink: PropTypes.string,
    blogLink: PropTypes.string || PropTypes.null,
  }),
}
