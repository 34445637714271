import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import * as styles from './index.module.css'
import PropTypes from 'prop-types'

import Layout from '../layout'
import Posts from '../components/Posts'
import { homepageTemplates } from '../data/templates'
import Template from '../components/Template'

const Home = ({ data }) => {
  const latestArticles = data.latest.edges
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showInstructions, setShowInstructions] = useState(false)
  const SOPHIA_EMAIL = 'sophiali124@gmail.com'
  const COPIED_EMAIL = 'email copied ✅'
  const INSTRUCTIONS = 'copy email 📋'

  function onHover() {
    if (!showSuccessMessage) {
      setShowInstructions(true)
    }
  }

  function onLeave() {
    setShowInstructions(false)
  }

  function copyEmail() {
    setShowInstructions(false)
    navigator.clipboard.writeText(SOPHIA_EMAIL)
    setShowSuccessMessage(true)
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.bioContainer}>
          <div className={styles.aboutContainer}>
            <h1 className={styles.title}>Sophia Li</h1>
            <p className={styles.subtitle}>
              Software Engineer at{' '}
              <span className={styles.bold}>Atlassian</span>.
            </p>
            <p className={styles.bioDescription}>
              Building collaboration tools. Writing about coding, career, and
              projects.
            </p>
          </div>
          <div>
            <img
              src="sophiaHeadshot.jpg"
              className={styles.headshot}
              alt="Photo of Sophia"
            />
          </div>
        </div>

        <section>
          <h2 className={styles.latestContainer}>
            <div>Latest Posts</div>
            <Link to="/blog" className={styles.viewAllLink}>
              View all
            </Link>
          </h2>
          {latestArticles.map(({ node: { frontmatter } }) => (
            <Posts frontmatter={frontmatter} key={frontmatter.path} />
          ))}
        </section>

        <section>
          <h2>
            <div>Templates</div>
          </h2>
          <div className={styles.templateContainer}>
            {homepageTemplates.map(template => (
              <Template template={template} key={template.title} />
            ))}
          </div>
        </section>

        <section>
          <h2>Connect</h2>
          <div>
            <p>
              If you'd like to get in touch, you can email me at{' '}
              <span
                className={
                  showSuccessMessage ? styles.copiedEmail : styles.email
                }
                onClick={copyEmail}
                onMouseOver={onHover}
                onMouseOut={onLeave}
              >
                {SOPHIA_EMAIL}
              </span>
              . {showSuccessMessage && <span>{COPIED_EMAIL}</span>}{' '}
              {showInstructions && <span>{INSTRUCTIONS}</span>}
            </p>
          </div>
        </section>

        <section className={styles.newsletterContainer}>
          <h2>Newsletter</h2>
          <div>
            <p>Sign up for updates on new blog posts.</p>
            <div>
              <a
                className={styles.subscribeButton}
                href="https://sophli.substack.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Subscribe to the Newsletter
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

Home.propTypes = {
  data: PropTypes.shape({
    latest: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
              path: PropTypes.string,
              tag: PropTypes.string,
              date: PropTypes.string,
              category: PropTypes.string,
            }),
          }),
        })
      ),
    }),
    highlights: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
              path: PropTypes.string,
              tag: PropTypes.string,
              date: PropTypes.string,
              category: PropTypes.string,
            }),
          }),
        })
      ),
    }),
  }),
}

export default Home

export const Latestquery = graphql`
  query HomepageQueryAnd {
    latest: allMarkdownRemark(
      limit: 5
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            tags
            date(formatString: "MMMM DD, YYYY")
            category
          }
        }
      }
    }
    highlights: allMarkdownRemark(
      limit: 5
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { category: { eq: "highlights" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            tags
            date(formatString: "MMMM DD, YYYY")
            category
          }
        }
      }
    }
  }
`
