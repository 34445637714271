export const templates = [
  {
    templateTitle: '1:1 Notes',
    description: 'Template for manager and direct report 1:1',
    googleDocsLink:
      'https://docs.google.com/document/d/1TP0BvjAuMdpQy9bw2AeGmfg5oDIDz-DqLLo6NmY-4bQ/edit?usp=sharing',
    blogLink: '/what-to-talk-about-in-a-1-1-with-your-manager',
  },
  {
    templateTitle: 'Working Styles',
    description: 'Workshop on team working style preferences',
    googleDocsLink:
      'https://docs.google.com/document/d/1AtmO0KD9XUMGtYHr0YmxUnoqwdQnXJkXQoJPwdz2S_Q/edit?usp=sharing',
    blogLink: '/team-workshop-working-styles',
  },
  {
    templateTitle: 'Onboarding Check-in',
    description: 'Check-in questions for improving onboarding',
    googleDocsLink:
      'https://docs.google.com/document/d/18Ejo1OxrUktQ7CSsjFQiCYa3wWCbRvAs5QLTri4PLeI/edit?usp=sharing',
  },
  {
    templateTitle: 'Onboarding Guide',
    description: 'A template for onboarding a new hire',
    googleDocsLink:
      'https://docs.google.com/document/d/1pOvgfPnepzmoXgYV3TTWRJlIO5uiAxAum5Ip30mLrzY/edit?usp=sharing',
    blogLink: '/onboarding-checklist',
  },
]

export const homepageTemplates = [templates[0], templates[1], templates[3]]
