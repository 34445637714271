// extracted by mini-css-extract-plugin
export var aboutContainer = "index-module--aboutContainer--jj9W3";
export var bioContainer = "index-module--bioContainer--r1D74";
export var bioDescription = "index-module--bioDescription--P0GYG";
export var bold = "index-module--bold--qhAtL";
export var container = "index-module--container--+MMgw";
export var copiedEmail = "index-module--copiedEmail--7A4SF";
export var email = "index-module--email--aNPyU";
export var headshot = "index-module--headshot--f5O52";
export var latestContainer = "index-module--latestContainer--JGVjr";
export var newsletterContainer = "index-module--newsletterContainer--Q31IW";
export var projectsContainer = "index-module--projectsContainer--zJ3BD";
export var subscribeButton = "index-module--subscribeButton--jEtUV";
export var subtitle = "index-module--subtitle--Q3P4u";
export var templateContainer = "index-module--templateContainer--0PgYs";
export var title = "index-module--title--FsR+z";
export var viewAllLink = "index-module--viewAllLink--lgpMH";